import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/lib/persistReducer";
import { ReducerType, State, Step } from './types';

/**
 * Notion認証へ遷移した場合に復帰できるように
 * ストアで状態を保存している。
 */
// 永続化の設定
const persistConfig = {
    key: 'setting',
    storage,
}

const initialState = {
    step: Step.SelectDataset,
    selectDatasetId: undefined,
    networkDefine: undefined,
} as State;

const reducer = (state = initialState, action: { type: ReducerType; payload: any; }): State => {
    switch(action.type) {
        case ReducerType.Initialize:
            return initialState;
        case ReducerType.SetStep:
            return Object.assign({}, state, {
                step: action.payload,
            });

        case ReducerType.SetSelectDatasetId:
            return Object.assign({}, state, {
                selectDatasetId: action.payload,
            });

        case ReducerType.SetNetworkDefine:
            return Object.assign({}, state, {
                networkDefine: action.payload,
            });


        default:
            return state;
    }
}

export default persistReducer(persistConfig, reducer);
