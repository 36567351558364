import { DbDefine } from "../../common/types";
import { DialogParam } from "../../components/common/AlertDialog";

export type Filter = {
    categories: {[id: string]: boolean};   // key = DBID-属性ID-カテゴリID, value = 表示or非表示
    keywords: string[]; // キーワード。AND検索。
}

/**
 * 操作説明
 * lang.tsのGuide_XXXXが対応する
 */
export enum GuideKind {
    NodeClick = 'NodeClick',
    CoreClick = 'CoreClick',
    EdgeClick = 'EdgeClick',
}
export enum TempGuideKind {
    CreateRelation = 'CreateRelation',
}
export type Guide = {
    kind: GuideKind;
    operationed: boolean;   // ユーザが操作したかどうか
}
export type TempGuide = {
    kind: TempGuideKind;
    onCancel?: () => void;
}
export type State = {
    visited: boolean;   // 初回アクセスかどうかを判断するためのフラグ。一度でもMainページに行ったらフラグが立つ。
    currentDatasetId: string | undefined;   // 現在選択中のデータセット
    filter: Filter,
    createPageDialogTarget: {
        dbDefine: DbDefine;
        position: {x: number; y: number};
     } | undefined;
    alertDialogInfo: {
        show: boolean;
        dialogParam: DialogParam | undefined;
    },
    guides: Guide[];
    tempGuide?:TempGuide;    // 一時的に表示する操作説明

    loadingInfo: {
        loading: boolean;
        status?: string;  // Loading Message
    };
}
export enum ReducerType {
    RefreshCategoryFilter = 'RefreshCategoryFilter',
    SetCategoryFilter = 'SetCategoryFilter',
    SetKeywordFilter = 'SetKeywordFilter',
    ClearFilter = 'ClearFilter',
    ShowCreatePageDialog = 'ShowCreatePageDialog',
    SetAlertDialogInfo = 'ShowAlertDialog',
    OperatedGuide = 'OperatedGuide',
    SetTempGuide = 'SetTempGuide',
    SetLoading = 'SetLoading',
    SetVisited = 'SetVisited',
    SetCurrentDataset = 'SetCurrentDataset',
}

export type SetCategoryFilterActionPayload = {
    categories: {
        dbId: string;
        propertyId: string;
        optionId: string;
        isShow: boolean;
    }[];
}
export type SetKeywordFilterActionPayload = {
    keywords: string[];
}
export type ShowCreatePageDialogActionPayload = {
    target: DbDefine;
    position?: {x: number; y: number;}
} | undefined;

export type SetAlertDialogInfoActionPayload = {
    show: boolean;
    dialogParam: DialogParam | undefined;
}
export type SetLoadingActionPayload = {
    loading: boolean;
    status?: string;
}

export type ActionTypes = 
    {
        type: ReducerType.RefreshCategoryFilter;
        payload: string[]; // 追加するカテゴリID一覧
    } | {
        type: ReducerType.SetCategoryFilter;
        payload: SetCategoryFilterActionPayload;
    } | {
        type: ReducerType.SetKeywordFilter;
        payload: SetKeywordFilterActionPayload;
    } | {
        type: ReducerType.ClearFilter;
    } | {
        type: ReducerType.ShowCreatePageDialog;
        payload: ShowCreatePageDialogActionPayload;
    } | {
        type: ReducerType.SetAlertDialogInfo;
        payload: SetAlertDialogInfoActionPayload;
    } | {
        type: ReducerType.OperatedGuide;
        payload: GuideKind;
    } | {
        type: ReducerType.SetKeywordFilter;
        payload: SetKeywordFilterActionPayload;
    } | {
        type: ReducerType.SetTempGuide;
        payload: TempGuide | undefined;
    } |  {
        type: ReducerType.SetLoading;
        payload: SetLoadingActionPayload;
    } | {
        type: ReducerType.SetVisited;
        payload: boolean;
    } | {
        type: ReducerType.SetCurrentDataset;
        payload: string | undefined;
    }
