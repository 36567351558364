import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiOAuth } from './useApi';
import { useDispatch } from 'react-redux';
import * as dataActions from '../store/data/actions';
import ErrorPage from '../ErrorPage';
import useOperation from '../store/operation/useOperation';

/**
 * Notionからのアクセストークンのリダイレクトページ
 */
export default function Callback() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const operation = useOperation();

    const [state, setState] = useState('getting' as 'getting' | 'success' | 'failure');

    useEffect(() => {
        // パラメタ取得
        let query = location.search;
        if (query.startsWith('?')) {
            query = query.substring(1);
        }
        const params = query.split('&');
        const paramMap = {} as {[key: string]: string};
        params.forEach(param => {
            const val = param.split('=');
            if (val.length === 2) {
                paramMap[val[0]] = val[1];
            }
        });

        if (!paramMap['code']) {
            console.warn('code not found');
            navigate('/notfound');
            return;
        }

        if (paramMap['state'] === 'dev') {
            // localhostでtokenを取得する用途
            return;
        }

        // トークン取得
        getToken(paramMap['code'])
        .catch(e => {
            setState('failure');
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getToken = useCallback(async(code: string) => {
        console.log('code=', code);

        // get a token
        const result = await apiOAuth(code);
        console.log('auth', result);

        // store the token
        dispatch(dataActions.addOAuthInfoAction(result));

        setState('success');

    }, [dispatch]);

    const { t } = useTranslation();

    useEffect(() => {
        if (state === 'getting') {
            operation.setLoadingAction(true, t('Getting_Access_Token'));
        } else {
            operation.setLoadingAction(false);
        }    
    }, [state, operation, t])

    if (state === 'getting') {
        return null;
    } else if (state === 'failure') {
        return (
            <ErrorPage message={t('Getting_Access_Token_Error')} />
        )
    } else {
        navigate('/')
        return null;
    }
}

