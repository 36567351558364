import { createTransform } from 'redux-persist';
import { DataSet } from '../../common/types';

/**
 * データ構造変更に伴う対応
 */
const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
        console.log('inboudState', inboundState, 'key', key);
        if (key === 'dataSets') {
            const dataSets = inboundState as DataSet[];
            return dataSets.map(ds => {
                const newDbList = ds.networkDefine.dbList.map(db => {
                    const newProperties = db.properties.map(prop => {
                        const newProp = Object.assign({}, prop);
                        // [rename] useFilter -> isUse 
                        if ('useFilter' in prop) {
                            // @ts-ignore
                            newProp.isUse = prop.useFilter;
                            // @ts-ignore
                            delete newProp.useFilter;
                        }
                        return newProp;
                    });
                    return Object.assign({}, db, {
                        properties: newProperties,
                    });
                });
                const newDs = Object.assign({}, ds);
                newDs.networkDefine.dbList = newDbList;
                return newDs;
            });
        } else {
        return inboundState;
        }
    },
    // transform state being rehydrated
    (outboundState, key) => {
        console.log('inboudState', outboundState, 'key', key);
        return outboundState;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['dataSets'] }
);

export default SetTransform;
