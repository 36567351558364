import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import lang from './common/lang';
import { BrowserRouter } from 'react-router-dom';

/**
 * store
 */
const store = configureStore({});
//@ts-ignore
const persistor = persistStore(store);

const detector = new LanguageDetector(null, {
    order: ['querystring', 'cookie',  'navigator', 'localStorage', 'htmlTag'],
    htmlTag: document.documentElement,
});
i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: lang,
        // lng: 'en',
    });
console.log('lang', i18n.language);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
