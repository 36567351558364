import { useCallback } from "react";
import { useDispatch } from "react-redux"
import { DbDefine } from "../../common/types";
import { ActionTypes, GuideKind, ReducerType, SetCategoryFilterActionPayload, TempGuideKind } from "./types"

export default function useOperation() {
    const dispatch = useDispatch();

    const refreshCategoryFilter = useCallback((categoryIds: string[]) => {
        dispatch<ActionTypes>({
            type: ReducerType.RefreshCategoryFilter,
            payload: categoryIds,
        });
    }, [dispatch]);
    const setCategoryFilter = useCallback((categories: SetCategoryFilterActionPayload) => {
        dispatch<ActionTypes>({
            type: ReducerType.SetCategoryFilter,
            payload: categories,
        });
    }, [dispatch]);
    const setKeywordFilter = useCallback((keywords: string[]) => {
        dispatch<ActionTypes>({
            type: ReducerType.SetKeywordFilter,
            payload: {
                keywords,
            },
        });
    }, [dispatch]);
    const clearFilter = useCallback(() => {
        dispatch<ActionTypes>({
            type: ReducerType.ClearFilter,
        });
    }, [dispatch]);
    const showCreatePageDialog = useCallback((target: DbDefine, position: {x: number; y: number}) => {
        dispatch<ActionTypes>({
            type: ReducerType.ShowCreatePageDialog,
            payload: {
                target,
                position,
            },
        });
    }, [dispatch]);
    const hideCreatePageDialog = useCallback(() => {
        dispatch<ActionTypes>({
            type: ReducerType.ShowCreatePageDialog,
            payload: undefined,
        });
    }, [dispatch]);
    
    const setLoadingAction = useCallback((isLoading: boolean, status?: string) => {
        dispatch<ActionTypes>({
            type: ReducerType.SetLoading,
            payload: {
                loading: isLoading,
                status,
            },
        });
    }, [dispatch]);
    const setVisitedAction = useCallback(() => {
        dispatch<ActionTypes>({
            type: ReducerType.SetVisited,
            payload: true,
        });
    }, [dispatch]);

    const setCurrentDatasetAction = useCallback((datasetId: string | undefined) => {
        dispatch<ActionTypes>({
            type: ReducerType.SetCurrentDataset,
            payload: datasetId,
        });
    }, [dispatch]);

    const operatedGuide = useCallback((kind: GuideKind) => {
        dispatch<ActionTypes>({
            type: ReducerType.OperatedGuide,
            payload: kind,
        });
    }, [dispatch]);
    
    const setTempGuide = useCallback((kind: TempGuideKind, onCancel?: () => void) => {
        dispatch<ActionTypes>({
            type: ReducerType.SetTempGuide,
            payload: {
                kind,
                onCancel,
            },
        });
    }, [dispatch]);

    const clearTempGuide = useCallback(() => {
        dispatch<ActionTypes>({
            type: ReducerType.SetTempGuide,
            payload: undefined,
        });
    }, [dispatch]);

    return {
        refreshCategoryFilter,
        setCategoryFilter,
        setKeywordFilter,
        clearFilter,
        showCreatePageDialog,
        hideCreatePageDialog,
        setLoadingAction,
        setVisitedAction,
        setCurrentDatasetAction,
        operatedGuide,
        setTempGuide,
        clearTempGuide,
    }
}