import { NetworkDefine } from "../../common/types";

export enum Step {
    SelectDataset,
    SelectDb,
    SelectRelationCol,
    SelectFilterCol,
}

/**
 * stored data
 */
export type State = {
    step: Step,
    selectDatasetId: string | undefined;
    networkDefine: NetworkDefine | undefined;
}

export enum ReducerType {
    Initialize,
    SetStep,
    SetSelectDatasetId,
    SetNetworkDefine,
}
