import { DataSet, Edge, NetworkDefine, NodeItem, NotionOauth, Property } from "../../common/types";

export type State = {
    dataSets: DataSet[];
    oAuthInfos: NotionOauth[];
}
export enum ReducerType {
    AddItems = 'AddItems',
    RemoveItems = 'RemoveItems',
    AddEdges = 'AddEdges',
    RemoveEdges = 'RemoveEdges',
    SetItemImageBase64 = 'SetItemImageBase64',
    SetFilterOptions = 'SetFilterOptions',
    SetLastEditedTime = 'SetLastEditedTime',
    SetPosition = 'SetPosition',
    CreateDataset = 'SetNetworkDefine',
    UpdateNetworkDefine = 'UpdateNetworkDefine',
    RemoveNetworkDefine = 'RemoveNetworkDefine',
    UpdateDatasetName = 'UpdateDatasetName',
    AddOAuthInfo = 'AddOAuthInfo',
}

export type AddItemsActionPayload = {
    datasetId: string;
    dbId: string;
    items: NodeItemWithPosition[];
}
export type NodeItemWithPosition = NodeItem & {
    position?: {x: number; y: number};
}

export type RemoveItemsActionPayload = {
    datasetId: string;
    dbId: string;
    ids: string[]; // 削除アイテムのID一覧
}
export type AddEdgesActionPayload = {
    datasetId: string;
    edges: Edge[];
}
export type RemoveEdgesActionPayload = {
    datasetId: string;
    edges: Edge[];
}

export type SetItemImageBase64ActionPayload = {
    datasetId: string;
    dbId: string;
    id: string;
    imageBase64: string;
}
export type SetPropertiesActionPayload = {
    datasetId: string;
    dbId: string;
    properties: Property[];
}
export type SetLastEditedTimeActionPayload = {
    datasetId: string;
    dbId: string;
    lastEditedTime: string;
}
export type SetPositionActionPayload = {
    datasetId: string;
    dbId: string;
    id: string;
    position: {
        x: number;
        y: number;
    };
}
export type CreateDatasetActionPayload = {
    networkDefine: NetworkDefine;
}
export type UpdateNetworkDefineActionPayload = {
    datasetId: string;
    networkDefine: NetworkDefine;
    dataClear: boolean; // trueの場合、データクリアする
}
export type RemoveNetworkDefineActionParam = {
    datasetId: string;
}

export type UpdateDatasetNameReducerParam = {
    datasetId: string;
    name: string;
}

export type AddOAuthInfoReducerParam = {
    oauthInfo: NotionOauth;
}
export type ActionTypes = {
    type: ReducerType.AddItems,
    payload: AddItemsActionPayload,
} | {
    type: ReducerType.RemoveItems,
    payload: RemoveItemsActionPayload,
} | {
    type: ReducerType.SetFilterOptions,
    payload: SetPropertiesActionPayload,
} | {
    type: ReducerType.SetItemImageBase64,
    payload: SetItemImageBase64ActionPayload,
} | {
    type: ReducerType.SetPosition,
    payload: SetPositionActionPayload,
} | {
    type: ReducerType.AddEdges,
    payload: AddEdgesActionPayload,
} | {
    type: ReducerType.RemoveEdges,
    payload: RemoveEdgesActionPayload,
} | {
    type: ReducerType.CreateDataset,
    payload: CreateDatasetActionPayload,
} | {
    type: ReducerType.UpdateNetworkDefine;
    payload: UpdateNetworkDefineActionPayload;
} | {
    type: ReducerType.SetLastEditedTime;
    payload: SetLastEditedTimeActionPayload;
} | {
    type: ReducerType.UpdateDatasetName;
    payload: UpdateDatasetNameReducerParam;
} | {
    type: ReducerType.RemoveNetworkDefine;
    payload: RemoveNetworkDefineActionParam;
} | {
    type: ReducerType.AddOAuthInfo;
    payload: AddOAuthInfoReducerParam;
}
