import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import Callback from './api/Callback';
import MarkdownViewer from './components/common/MarkdownViewer';
import { RootState } from './store/reducers';
import { Spinner } from 'react-bootstrap';
import ErrorPage from './ErrorPage';

const Main = React.lazy(() => import('./Main'));
const Welcome = React.lazy(() => import('./Welcome'));

export default function App() {
    const location = useLocation();
    const visited = useSelector((state: RootState) => state.operationRecuder.visited);

    const loading = useSelector((state: RootState) => state.operationRecuder.loadingInfo.loading);

    if (location.pathname === '/' && !visited) {
        // 初回アクセスの場合は、Welcomeページへ遷移
        location.pathname = '/welcome';
    }

    return (
        <div>
            <Routes>
                <Route path="/callback" element={
                    <Callback />
                } />
                <Route path="/welcome" element={
                    <React.Suspense fallback={<Loading />}>
                        <Welcome />
                    </React.Suspense>
                } />
                <Route path="/privacy-policy" element={
                    <React.Suspense fallback={<Loading />}>
                        <MarkdownViewer mdfileName='privacy_policy.md' showBack />
                    </React.Suspense>
                } />
                <Route path="/terms-of-use" element={
                    <React.Suspense fallback={<Loading />}>
                        <MarkdownViewer mdfileName='terms_of_use.md' showBack />
                    </React.Suspense>
                } />
                <Route index element={
                    <React.Suspense fallback={<Loading />}>
                        <Main />
                    </React.Suspense>
                } />
                <Route path="*"  element={<ErrorPage message="Not Found" status={404} />} />
            </Routes>
            <div className={styles.Footer}>presented by Satocheeen.com</div>
            {loading &&
                <Loading />
            }
        </div>
    );
}

function Loading() {
    const status = useSelector((state: RootState) => {
        const status = state.operationRecuder.loadingInfo.status;
        return status ? status : '';
    });
    return (
        <div className={styles.SpinnerOverlay}>
            <div className={styles.GraphSpinner}>
                <Spinner animation='border' variant='info'/>
            </div>
            <p>{status}</p>
        </div>
    )
}

