import styles from './ErrorPage.module.scss';

type Props = {
    message: string;
    status?: number;
}
export default function Error(props: Props) {
    return (
        <div className={styles.Message}>{props.message}</div>
    )
}