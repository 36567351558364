import { combineReducers } from "redux";
import dataRecuder from './data/reducers';
import operationRecuder from './operation/reducers';
import settingReducer from '../components/setting/reducers';

const rootReducer = combineReducers({
    dataRecuder,
    operationRecuder,
    settingReducer,
});
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>
