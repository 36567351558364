import { ActionTypes, ReducerType, State, ShowCreatePageDialogActionPayload, SetAlertDialogInfoActionPayload, GuideKind, SetKeywordFilterActionPayload, SetLoadingActionPayload, TempGuide, SetCategoryFilterActionPayload } from "./types";
import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/lib/persistReducer";

// 永続化の設定
const persistConfig = {
    key: 'operation',
    storage,
    whitelist: ['visited', 'currentDatasetId', 'guides'],
}

/**
 * initialize State
 */
const initGuides = Object.keys(GuideKind).map((kind) => {
    return {
        kind,
        operationed: false,
    }
});

const initialState = {
    visited: false,
    currentDatasetId: undefined,
    filter: {
        categories: {},
        keywords: [],
    },
    createPageDialogTarget: undefined,
    alertDialogInfo: {
        show: false,
        dialogParam: undefined,
    },
    guides: initGuides,
    loadingInfo: {
        loading: false,
    },
} as State;

const reducer = (state = initialState, action: ActionTypes): State => {
    switch(action.type) {
        case ReducerType.RefreshCategoryFilter:
            return refreshCategoryFilterReducer(state, action.payload);
        case ReducerType.SetCategoryFilter:
            return setCategoryFilterReducer(state, action.payload);
        case ReducerType.SetKeywordFilter:
            return setKeywordFilterReducer(state, action.payload);
        case ReducerType.ClearFilter:
            return clearFilterReducer(state);
        case ReducerType.ShowCreatePageDialog:
            return showCreatePageDialogReducer(state, action.payload);
        case ReducerType.SetAlertDialogInfo:
            return setAlertDialogInfoReducer(state, action.payload);
        case ReducerType.OperatedGuide:
            return operatedGuideReducer(state, action.payload);
        case ReducerType.SetTempGuide:
            return setTemoGuideReducer(state, action.payload);
        case ReducerType.SetLoading:
            return setLoadingReducer(state, action.payload);
        case ReducerType.SetVisited:
            return setVisitedReducer(state, action.payload);
        case ReducerType.SetCurrentDataset:
            return setCurrentDatasetReducer(state, action.payload);
        default:
            return state;
    }
};
export default persistReducer(persistConfig, reducer);

/**
 * カテゴリを以下ルールでリフレッシュする
 * - 新たなカテゴリ -> 選択中として追加
 * - 既存 -> 何もしない
 * - 削除 -> 削除する
 * 指定のカテゴリをフィルタ設定に追加する
 */
function refreshCategoryFilterReducer(state: State, payload: string[]) {
    const categories = Object.assign({}, state.filter.categories);
    payload.forEach(id => {
        if (Object.keys(categories).indexOf(id) === -1) {
            // 追加
            categories[id] = true;
        }
    });
    Object.keys(state.filter.categories).forEach(id => {
        if (payload.indexOf(id) === -1) {
            // 削除
            delete categories[id];
        }
    })
    return Object.assign({}, state, {
        filter: Object.assign({}, state.filter, {
            categories,
        })
    });
}
/**
 * 指定のカテゴリのフィルタ設定を切り替える
 * @param payload 
 */
function setCategoryFilterReducer(state: State, payload: SetCategoryFilterActionPayload) {
    const categories = Object.assign({}, state.filter.categories);
    payload.categories.forEach(category => {
        const key = category.dbId + '-' + category.propertyId + '-' + category.optionId;
        categories[key] = category.isShow;
    });
    return Object.assign({}, state, {
        filter: Object.assign({}, state.filter, {
            categories,
        })
    });
}
function setKeywordFilterReducer(state: State, payload: SetKeywordFilterActionPayload) {
    return Object.assign({}, state, {
        filter: Object.assign({}, state.filter, {
            keywords: payload.keywords,
        })
    });
}
/**
 * フィルタ設定をリセットする
 * @param state 
 */
function clearFilterReducer(state: State) {
    const categories = Object.assign({}, state.filter.categories);
    Object.keys(categories).forEach(key => {
        categories[key] = true;
    })
    return Object.assign({}, state, {
        filter: Object.assign({}, state.filter, {
            categories,
            keywords: [],
        })
    });
}
function showCreatePageDialogReducer(state: State, payload: ShowCreatePageDialogActionPayload) {
    return Object.assign({}, state, {
        createPageDialogTarget: (!payload || !payload.target) ? undefined : {
            dbDefine: payload.target,
            position: payload.position,
        },
    });
}

function setAlertDialogInfoReducer(state: State, payload: SetAlertDialogInfoActionPayload) {
    return Object.assign({}, state, {
        alertDialogInfo: payload,
    });
}

function operatedGuideReducer(state: State, payload: GuideKind) {
    const newGuides = state.guides.map(guide => {
        if (guide.kind === payload && !guide.operationed) {
            return {
                kind: guide.kind,
                operationed: true,
            }
        } else {
            return guide;
        }
    });
    return Object.assign({}, state, {
        guides: newGuides,
    });
}

function setTemoGuideReducer(state: State, payload: TempGuide | undefined) {
    return Object.assign({}, state, {
        tempGuide: payload
    })
}
function setLoadingReducer(state: State, payload: SetLoadingActionPayload) {
    return Object.assign({}, state, {
        loadingInfo: {
            loading: payload.loading,
            status: payload.loading ? payload.status : undefined,
        },
    });
}
function setVisitedReducer(state: State, payload: boolean) {
    return Object.assign({}, state, {
        visited: payload,
    });
}
function setCurrentDatasetReducer(state: State, payload: string | undefined) {
    return Object.assign({}, state, {
        currentDatasetId: payload,
    });
}
