import { ActionTypes, SetPropertiesActionPayload, AddEdgesActionPayload, AddItemsActionPayload, ReducerType, RemoveItemsActionPayload, SetItemImageBase64ActionPayload, CreateDatasetActionPayload, SetPositionActionPayload, State, UpdateNetworkDefineActionPayload, RemoveNetworkDefineActionParam, UpdateDatasetNameReducerParam, RemoveEdgesActionPayload, SetLastEditedTimeActionPayload, AddOAuthInfoReducerParam } from "./types";
import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/lib/persistReducer";
import { DbData, Edge, PositionData } from "../../common/types";
import { isSameProperty } from "./util";
import SetTransform from './transforms';

// 永続化の設定
const persistConfig = {
    key: 'data',
    storage,
    transforms: [SetTransform],
}

const initialState = {
    dataSets: [],
    oAuthInfos: [],
} as State;

const reducer = (state = initialState, action: ActionTypes): State => {
    switch(action.type) {
        case ReducerType.AddItems:
            return addItemsReducer(state, action.payload);
        case ReducerType.RemoveItems:
            return removeItemsReducer(state, action.payload);
        case ReducerType.AddEdges:
            return addEdgesReducer(state, action.payload);
        case ReducerType.RemoveEdges:
            return removeEdgesReducer(state, action.payload);
        case ReducerType.SetItemImageBase64:
            return setItemImageBase64(state, action.payload);
        case ReducerType.SetFilterOptions:
            return setFilterOptionsReducer(state, action.payload);
        case ReducerType.SetLastEditedTime:
            return setLastEditedTimeReducer(state, action.payload);
        case ReducerType.SetPosition:
            return setPositionReducer(state, action.payload);
        case ReducerType.CreateDataset:
            return createDatasetReducer(state, action.payload);
        case ReducerType.UpdateNetworkDefine:
            return updateNetworkDefineReducer(state, action.payload);
        case ReducerType.RemoveNetworkDefine:
            return removeNetworkDefineReducer(state, action.payload);
        case ReducerType.UpdateDatasetName:
            return updateDatasetNameReducer(state, action.payload);
        case ReducerType.AddOAuthInfo:
            return addOAuthInfoReducer(state, action.payload);
        default:
            return state;
    }
};
export default persistReducer(persistConfig, reducer);

type CreateStateParam = {
    datasetId: string;
    dataMap?: {[id: string]: DbData};
    positionMap?: {[id: string]: PositionData};
    edges?: Edge[];
}
function createStateReplacedDataMap(state: State, param: CreateStateParam) {
    const index = state.dataSets.findIndex(ds => ds.id === param.datasetId);
    if (index === -1) {
        return state;
    }

    const newDataSets = state.dataSets.concat();
    if (param.dataMap) {
        newDataSets[index].dataMap = param.dataMap;
    }
    if (param.positionMap) {
        newDataSets[index].positionMap = param.positionMap;
    }
    if (param.edges) {
        newDataSets[index].edges = param.edges;
    }
    return Object.assign({}, state, {
        dataSets: newDataSets,
    });
}

function addItemsReducer(state: State, payload: AddItemsActionPayload) {
    const currentDataset = state.dataSets.find(ds => ds.id === payload.datasetId);
    if (!currentDataset) {
        return state;
    }
    console.log('currentDataset', currentDataset);
    const items = currentDataset.dataMap[payload.dbId]?.items ? currentDataset.dataMap[payload.dbId].items.concat() : [];
    const newPositionMap = Object.assign({}, currentDataset.positionMap);
    payload.items.forEach(newItem => {
        const index = items.findIndex(item => item.id === newItem.id);
        if (index === -1) {
            items.push(newItem);
        } else {
            items.splice(index, 1, newItem);
        }

        if (newItem.position) {
            if (newPositionMap[payload.dbId] === undefined) {
                newPositionMap[payload.dbId] = {};
            }
            newPositionMap[payload.dbId][newItem.id] = newItem.position;
        }
    })
    const newDataMap = Object.assign({}, currentDataset.dataMap);
    newDataMap[payload.dbId] = {
        id: payload.dbId,
        items,
    } as DbData;

    return createStateReplacedDataMap(state, {
        datasetId: payload.datasetId,
        dataMap: newDataMap,
        positionMap: newPositionMap,
    });
}
function removeItemsReducer(state: State, payload: RemoveItemsActionPayload) {
    const currentDataset = state.dataSets.find(ds => ds.id === payload.datasetId);
    if (!currentDataset) {
        return state;
    }
    const items = currentDataset.dataMap[payload.dbId]?.items ? currentDataset.dataMap[payload.dbId].items.concat() : [];
    payload.ids.forEach(deleteId => {
        const index = items.findIndex(item => item.id === deleteId);
        if (index !== -1) {
            items.splice(index, 1);
        }
    });

    const newDataMap = Object.assign({}, currentDataset.dataMap);
    newDataMap[payload.dbId].items = items;

    return createStateReplacedDataMap(state, {
        datasetId: payload.datasetId,
        dataMap: newDataMap
    });
}
function addEdgesReducer(state: State, payload: AddEdgesActionPayload) {
    const currentDataset = state.dataSets.find(ds => ds.id === payload.datasetId);
    if (!currentDataset) {
        console.warn('[addEdgesReducer] dataset not found');
        return state;
    }
    console.log('addEdgesReducer');
    const newEdges = currentDataset.edges.concat();
    payload.edges.forEach(edge => {
        const isSame = isSameProperty(edge.def.from, edge.def.to);
        const exist = newEdges.some(cur => {
            if (cur.from === edge.from && cur.to === edge.to) {
                return true;
            }
            // from,toの項目が同じ場合は、from, toが逆でも等しいものと見なす
            if (isSame) {
                if (cur.from === edge.to && cur.to === edge.from) {
                    return true;
                }
            }
            return false;
        });
        if (!exist) {
            newEdges.push(edge);
        }
    })
    return createStateReplacedDataMap(state, {
        datasetId: payload.datasetId,
        edges: newEdges,
    });
}

function removeEdgesReducer(state: State, payload: RemoveEdgesActionPayload) {
    const currentDataset = state.dataSets.find(ds => ds.id === payload.datasetId);
    if (!currentDataset) {
        return state;
    }

    const newEdges = currentDataset.edges.filter(edge => {
        const isDelTarget = payload.edges.some(delEdge => {
            return Object.is(edge.def, delEdge.def)
                    && edge.from === delEdge.from
                    && edge.to === delEdge.to;
        });
        return !isDelTarget;
    });
    return createStateReplacedDataMap(state, {
        datasetId: payload.datasetId,
        edges: newEdges,
    });
}

function setItemImageBase64(state: State, payload: SetItemImageBase64ActionPayload) {
    const currentDataset = state.dataSets.find(ds => ds.id === payload.datasetId);
    if (!currentDataset) {
        return state;
    }
    const items = currentDataset.dataMap[payload.dbId]?.items ? currentDataset.dataMap[payload.dbId].items.concat() : [];
    const item = items.find(item => item.id === payload.id);
    if (!item) {
        return state;
    }
    item.imageBase64 = payload.imageBase64;
    item.imageGotFlag = true;
    delete item.image;

    const newDataMap = Object.assign({}, currentDataset.dataMap);
    newDataMap[payload.dbId].items = items;
    return createStateReplacedDataMap(state, {
        datasetId: payload.datasetId,
        dataMap: newDataMap
    });
}

/**
 * 指定のDBのプロパティ項目の定義を更新する。
 * TODO: 現状は、isUse=trueの項目のみ引数で渡されてくるが、
 * 全プロパティの最新情報が渡されてくるように変更してもいいかもしれない
 * @returns 
 */
function setFilterOptionsReducer(state: State, payload: SetPropertiesActionPayload) {
    const currentDataset = state.dataSets.find(ds => ds.id === payload.datasetId);
    if (!currentDataset) {
        return state;
    }

    const newDataSets = state.dataSets.map(ds => {
        if (ds.id !== payload.datasetId) {
            return ds;
        }

        const newDbLiset = ds.networkDefine.dbList.map(db => {
            if (db.id !== payload.dbId) {
                return db;
            }
            return Object.assign({}, db, {
                properties: db.properties.map(prop => {
                    const optionInfo = payload.properties.find(pps => pps.id === prop.id);
                    if (!optionInfo) {
                        return prop;
                    }
                    return Object.assign({}, prop, {
                        options: optionInfo.options,
                    });
                }),
            });
        });

        return Object.assign({}, ds, {
            networkDefine: Object.assign({}, ds.networkDefine, {
                dbList: newDbLiset,
            }),
        });
    });

    return Object.assign({}, state, {
        dataSets: newDataSets,
    });

}
function setLastEditedTimeReducer(state: State, payload: SetLastEditedTimeActionPayload) {
    const currentDataset = state.dataSets.find(ds => ds.id === payload.datasetId);

    const newDataMap = Object.assign({}, currentDataset?.dataMap);
    newDataMap[payload.dbId].lastEditedTime = payload.lastEditedTime;

    return createStateReplacedDataMap(state, {
        datasetId: payload.datasetId,
        dataMap: newDataMap
    });
}
function setPositionReducer(state: State, payload: SetPositionActionPayload) {
    const currentDataset = state.dataSets.find(ds => ds.id === payload.datasetId);
    if (!currentDataset) {
        return state;
    }
    const index = currentDataset.dataMap[payload.dbId]?.items.findIndex(item => item.id === payload.id);
    if (index === -1) {
        return state;
    }
    const newPositionMap = Object.assign({}, currentDataset.positionMap);
    if (newPositionMap[payload.dbId] === undefined) {
        newPositionMap[payload.dbId] = {};
    }
    newPositionMap[payload.dbId][payload.id] = payload.position;

    return createStateReplacedDataMap(state, {
        datasetId: payload.datasetId,
        positionMap: newPositionMap,
    });
}
/**
 * 新規データセットを作成する
 * @returns 
 */
function createDatasetReducer(state: State, payload: CreateDatasetActionPayload) {
    const maxId = state.dataSets.reduce((acc, cur) => {
        return Math.max(acc, parseInt(cur.id));
    }, 0);
    const id = maxId + 1 + '';
    const name = payload.networkDefine.dbList[0].name;
    const newDatasets = state.dataSets.concat({
        id,
        name,
        networkDefine: payload.networkDefine,
        dataMap: {},
        positionMap: {},
        edges: [],
    });
    console.log('create dataset', id);
    return Object.assign({}, state, {
        dataSets: newDatasets,
    });
}
function updateNetworkDefineReducer(state: State, payload: UpdateNetworkDefineActionPayload) {
    const dataset = state.dataSets.find(ds => ds.id === payload.datasetId);
    if (!dataset) {
        console.warn('Datasetなし');
        return state;
    }

    const newDataset = Object.assign({}, dataset);
    newDataset.networkDefine = payload.networkDefine;
    if (payload.dataClear) {
        // 位置情報以外はクリア
        newDataset.dataMap = {};
        newDataset.edges = [];
    }

    const newDatasets = state.dataSets.map(ds => {
        if (ds.id === payload.datasetId) {
            return newDataset;
        } else {
            return ds;
        }
    });

    return Object.assign({}, state, {
        dataSets: newDatasets,
    });
}
function removeNetworkDefineReducer(state: State, payload: RemoveNetworkDefineActionParam) {
    const newDatasets = state.dataSets.filter(ds => ds.id !== payload.datasetId);

    return Object.assign({}, state, {
        dataSets: newDatasets,
    });
}
function updateDatasetNameReducer(state: State, payload: UpdateDatasetNameReducerParam) {
    const newDatasets = state.dataSets.map(ds => {
        if (ds.id !== payload.datasetId) {
            return ds;
        }
        const newDs = Object.assign({}, ds);
        newDs.name = payload.name;
        return newDs;
    });

    return Object.assign({}, state, {
        dataSets: newDatasets,
    });
}
function addOAuthInfoReducer(state: State, payload: AddOAuthInfoReducerParam) {
    let exist = false;
    const newOAuthInfos = state.oAuthInfos.map(info => {
        if (info.workspace_id === payload.oauthInfo.workspace_id) {
            // 差し替え
            exist = true;
            return payload.oauthInfo;
        } else {
            return info;
        }
    });
    if (!exist) {
        newOAuthInfos.push(payload.oauthInfo);
    }
    return Object.assign({}, state, {
        oAuthInfos: newOAuthInfos,
    });
}
