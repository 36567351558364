import { Edge, NetworkDefine, NodeItem, NotionOauth, Property } from "../../common/types";
import { SetPropertiesActionPayload, AddEdgesActionPayload, AddItemsActionPayload, ReducerType, RemoveItemsActionPayload, SetItemImageBase64ActionPayload, SetPositionActionPayload, UpdateNetworkDefineActionPayload, RemoveNetworkDefineActionParam, UpdateDatasetNameReducerParam, RemoveEdgesActionPayload, NodeItemWithPosition, SetLastEditedTimeActionPayload, AddOAuthInfoReducerParam } from "./types";

export function addItemsAction(datasetId: string, dbId: string, items: (NodeItem | NodeItemWithPosition)[]) {
    return {
        type: ReducerType.AddItems,
        payload: { datasetId, dbId, items } as AddItemsActionPayload,
    };
}
export function removeItemsAction(datasetId: string, dbId: string, ids: string[]) {
    return {
        type: ReducerType.RemoveItems,
        payload: { datasetId, dbId, ids } as RemoveItemsActionPayload,
    };
}
export function addEdgesAction(datasetId: string, edges: Edge[]) {
    return {
        type: ReducerType.AddEdges,
        payload: {
            datasetId,
            edges,
        } as AddEdgesActionPayload,
    }
}
export function removeEdgesAction(datasetId: string, edges: Edge[]) {
    return {
        type: ReducerType.RemoveEdges,
        payload: {
            datasetId,
            edges,
        } as RemoveEdgesActionPayload,
    }
}
export function setItemImageBase64Action(datasetId: string, dbId: string, id: string, base64: string) {
    return {
        type: ReducerType.SetItemImageBase64,
        payload: {
            datasetId,
            dbId,
            id,
            imageBase64: base64,
        } as SetItemImageBase64ActionPayload,
    };
}
export function setFilterOptionsAction(datasetId:string, dbId: string, properties: Property[]) {
    return {
        type: ReducerType.SetFilterOptions,
        payload: {
            datasetId,
            dbId,
            properties: properties
        } as SetPropertiesActionPayload,
    };
}
export function setLastEditedTimeAction(datasetId:string, dbId: string, lastEditedTime: string) {
    return {
        type: ReducerType.SetLastEditedTime,
        payload: {
            datasetId,
            dbId,
            lastEditedTime,
        } as SetLastEditedTimeActionPayload,
    };

}
export function setPositionAction(datasetId: string, dbId: string, id: string, position: {x: number; y: number}) {
    return {
        type: ReducerType.SetPosition,
        payload: {
            datasetId,
            dbId,
            id,
            position,
        } as SetPositionActionPayload,
    };
}
export function updateNetworkDefineAction(datasetId: string, networkDefine: NetworkDefine, dataClear: boolean) {
    return {
        type: ReducerType.UpdateNetworkDefine,
        payload: {
            datasetId,
            networkDefine,
            dataClear,
        } as UpdateNetworkDefineActionPayload,
    }
}
export function removeNetworkDefineAction(datasetId: string) {
    return {
        type: ReducerType.RemoveNetworkDefine,
        payload: {
            datasetId,
        } as RemoveNetworkDefineActionParam,
    }
}
export function updateDatasetNameAction(datasetId: string, name: string) {
    return {
        type: ReducerType.UpdateDatasetName,
        payload: {
            datasetId,
            name,
        } as UpdateDatasetNameReducerParam,
    }
}
export function addOAuthInfoAction(oauthInfo: NotionOauth) {
    return {
        type: ReducerType.AddOAuthInfo,
        payload: {
            oauthInfo,
        } as AddOAuthInfoReducerParam,
    };
}